<template>
    <div id="NewContainer">
        <div id="header">
            <div class="header_icons">
                {{ puntos }} puntos
            </div>
            <div class="header_icons">
                <img src="../assets/icono_speaker.svg" alt="Sonido" class="icono">
                <router-link to="/"><img @click="soundButton.play()" src="../assets/icono_home.svg" alt="Inicio" class="icono"></router-link>
            </div>
        </div>

        <div id="agrupador">
            <div id="titulo_combo">Combo</div>
            <div id="contenedor_combo">
                <div id="contenedor_rosado">
                    <img id="contenedor_rosado_personaje" src="../assets/logo_pera.svg">
                    <div id="contenedor_rosado_texto">
                        <div id="contenedor_rosado_multiplicador">X{{ multiplicador }}</div>
                        <div id="contenedor_rosado_racha">Tienes una racha de {{ 5 * Math.log2(multiplicador) }} aciertos</div>
                    </div>
                </div>
                <router-link to="/juego/noticia">
                    <div @click="soundButton.play()" id="boton_continuar">Continuar <img src="../assets/icono_luparosa.svg" alt="Inicio" class="lupa"> </div>
                </router-link>
            </div>
        </div>

        <div></div>
    </div>
</template>

<script>
import useJuego from '../store/contenidoJuego'

export default {
  mounted () {
    const soundCombo = document.querySelector('#soundCombo')
    soundCombo.play()
  },
  data () {
    const gameState = useJuego()

    return {
      multiplicador: gameState.multiplicador.value,
      puntos: gameState.puntos.value,
      soundButton: document.querySelector('#soundButton')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#NewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}
#agrupador {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.header_icons {
    display: flex;
    align-items: center;
    color: white;
    font: 25px "Bebas Neue";
}
.icono {
    width: 25px;
    height: auto;
    margin: 0px 4px;
}
.icono:hover {
    cursor: pointer;
    transform: scale(1.1);
}

#titulo_combo {
    background-color: #54ceea;
    color: #0C1D3E;
    font: 60px "Bebas Neue";
    line-height: 0.8;
    width: 65%;
    padding: 10px 0px 5px;

    border: 2px solid white;
    border-radius: 15px;

    margin-bottom: -15px;
    z-index: 1;
}

#contenedor_combo {
    background-color: white;
    width: 90%;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
#contenedor_rosado {
    background-color: #f2ccf2;
    background-image: url("../assets/LineasFondoIzq.svg"), url("../assets/LineasFondoDer.svg");
    background-repeat: no-repeat, no-repeat;
    background-size: 20%, 20%;
    background-position: left, right;
    background-origin: content-box, content-box;
    border-radius: 15px;
    width: 100%;
    height: 25vh;
    padding: 20px 10px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#contenedor_rosado_personaje {
    max-height: 100%;
    width: 50%;
    margin-right: -40px;
    transition-duration: 200ms;
}
#contenedor_rosado_personaje:hover {
    transform: scale(1.1);
}
#contenedor_rosado_texto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 2px;
    margin: 20px 0px;
    height: 100%;
    width: 50%;
}
#contenedor_rosado_multiplicador {
    color: #0C1D3E;
    font: 15vh "Bebas Neue";
    line-height: 1;
}
#contenedor_rosado_racha {
    color: #0C1D3E;
    font: 3vh "Bebas Neue";
    line-height: 1;
    width: 100%;
    margin-top: -10px;
}

#boton_continuar {
    background-color: #0C1D3E;
    border: 2px solid white;
    border-radius: 10px;
    color: white;
    font: 30px "Bebas Neue";
    line-height: 1;
    padding: 4px 10px 0px;
    margin: 5px 0px 5px;

    display: flex;
    align-items: center;
}
#boton_continuar:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.lupa {
    width: 25px;
    height: auto;
    margin: 0px 0px 4px 6px;
}

@media screen and (min-width: 800px) {
    #agrupador {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #titulo_combo {
        background-color: #54ceea;
        color: #0C1D3E;
        font: 75px "Bebas Neue";
        line-height: 0.8;
        width: 25%;
        padding: 10px 0px 5px;

        border: 2px solid white;
        border-radius: 15px;

        margin-bottom: -15px;
        z-index: 1;
    }
    #contenedor_rosado {
        background-color: #f2ccf2;
        background-image: url("../assets/LineasFondoIzq.svg"), url("../assets/LineasFondoDer.svg");
        background-repeat: no-repeat, no-repeat;
        background-size: 15%, 15%;
        background-position: left, right;
        background-origin: content-box, content-box;
        border-radius: 15px;
        width: 100%;
        height: 40vh;
        padding: 20px 100px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #contenedor_rosado_texto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 2px;
        margin: 20px 0px;
        height: 100%;
        width: 50%;
    }
    #contenedor_rosado_multiplicador {
        color: #0C1D3E;
        font: 25vh "Bebas Neue";
        line-height: 1;
    }
    #contenedor_rosado_racha {
        color: #0C1D3E;
        font: 3vh "Bebas Neue";
        line-height: 1;
        width: 60%;
        margin-top: -10px;
    }

    #boton_continuar {
        background-color: #0C1D3E;
        border: 2px solid white;
        border-radius: 10px;
        color: white;
        font: 42px "Bebas Neue";
        line-height: 1;
        padding: 4px 10px 0px;
        margin: 10px 0px 10px;

        display: flex;
        align-items: center;
    }
    .lupa {
        width: 35px;
        height: auto;
        margin: 0px 0px 4px 6px;
    }
}
</style>
